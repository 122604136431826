const LogLevel = {
  Debug: 'debug',
  Info: 'info',
  Warn: 'warn',
  Error: 'error',
};

const isDev = process.env.NODE_ENV === 'development';

class Logger {
  static log(msg, body, level = LogLevel.Debug) {
    if (isDev && level === LogLevel.Debug) {
      // Uncomment to log debug messages in development
      // console.log(`[${level}]: ${msg}`, body);
    } else if (level !== LogLevel.Debug) {
      const method = level === LogLevel.Info ? 'log' : level;
      // Uncomment to log messages other than debug
      // console[method](`[${level}]: ${msg}`, body);
    }
  }

  static error(msg, body) {
    // Uncomment to log error messages
    // console.error(`[error]: ${msg}`, body);
  }
}

export {
  LogLevel,
  Logger
};
