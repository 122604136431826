import React, { useCallback, useEffect, useMemo, useState } from "react";

// components

import CardTable from "components/Cards/CardTable.js";
import { InvitationService } from "libs/api/services/InvitationService";
import { useHistory } from "react-router";
import { isAdmin } from "utils/CommonFnc";

export default function Invitations() {
  const history = useHistory();
  const [invitations, setInvitations] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState();

  const getInvitations = useCallback(async () => {
    const response = await InvitationService.getList(page);
    if (response.ok) {
      setInvitations(response.data.data);
      setLast_page(response.data.meta.last_page);
    }
  }, [page]);

  const handleDelete = useCallback(async (id) => {
    const response = await InvitationService.delete(id);
    if (response.ok) {
      getInvitations();
    }
  }, [getInvitations]);

  const goToCreate = useCallback(() => {
    history.push('/admin/invitations/create')
  }, [history])

  const goToGuests = useCallback((id) => {
    history.push(`/admin/invitations/${id}/guests`)
  }, [history])

  const goToComments = useCallback((id) => {
    history.push(`/admin/invitations/${id}/comments`)
  }, [history])

  const goToEditForm = useCallback((id) => {
    history.push(`/admin/invitations/${id}`)
  }, [history])

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  const actions = [
    { name: "Guests", fnc: (row) => goToGuests(row.id) },
    { name: "Comments", fnc: (row) => goToComments(row.id) },
    { name: "Edit", fnc: (row) => goToEditForm(row.id), type: "edit", show: isAdmin() },
    { name: "Delete", fnc: (row) => handleDelete(row.id), type: "delete", show: isAdmin() },
  ];

  const columns = useMemo(() => [
    { name: "User", col: "userId.email", width: '250px' },
    { name: "Name", col: "name", width: '250px' },
    { name: "URL", col: "url", link: true, show: isAdmin() },
    { name: "Guests", col: "guestCount" },
    { name: "Comments", col: "commentCount" },
    { name: "Published", col: "isPublished", type: "check_box", position: "center", show: isAdmin() },
  ], []);

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {/* <CardTable /> */}
          <CardTable
            title="Invitations"
            hasNo
            page={page}
            setPage={setPage}
            last_page={last_page}
            columns={columns}
            data={invitations}
            actions={actions}
            hasCreateBtn
            onClickCreate={goToCreate}
          />
        </div>
      </div>
    </>
  );
}
