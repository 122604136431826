import React, { useCallback, useEffect, useState } from "react";

// components

import CardTable from "components/Cards/CardTable.js";
import { UserService } from "libs/api/services/UserService";
import { useHistory } from "react-router";

export default function Users() {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      const response = await UserService.getUsers();
      if (response.ok) {
        setUsers(response.data);
      }
    }

    getUsers();
  }, [])

  const goToCreate = useCallback(() => {
    history.push('/admin/users/create')
  }, [history])

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">
          {/* <CardTable /> */}
          <CardTable title="Users" hasNo color="light" columns={[
            { name: "Name", col: "name" },
            { name: "Email", col: "email" },
            { name: "Phone", col: "phone" },
            { name: "Roles", col: "roles" },
            { name: "Invitations", col: "invitationCount", position: "center" },
          ]} data={users} hasCreateBtn onClickCreate={goToCreate} />
        </div>
      </div>
    </>
  );
}
