// Button.js
const Button = ({
  text,
  onClick,
  buttonClass = "",
  type = "button",
}) => {
  return (
    <button
      className={`bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 ${buttonClass}`}
      type={type}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
