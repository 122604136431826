import Cookie from 'js-cookie';

export function setToken(value) {
    localStorage.setItem('token', value);
}

export function getToken() {
    return localStorage.getItem('token');
}

export function setUser(value) {
    localStorage.setItem('user', JSON.stringify(value));
}

export function getUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : [];
}

export function setValue(key, value) {
    localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
}

export function getValue(key) {
    try {
        const storedValue = localStorage.getItem(key);
        return storedValue ? typeof storedValue === 'string' ? storedValue : JSON.parse(storedValue) : null;
    } catch (error) {
        return null;
    }
}

export function removeValue(key) {
    return localStorage.removeItem(key)
}

export function setLoginParams(value) {
    localStorage.setItem('login_params', JSON.stringify(value));
}
export function getLoginParams() {
    const p = localStorage.getItem('login_params')
    if (p) {
        return JSON.parse(p);
    }
    return p
}

export function clearLocalStorage() {
    console.log("CLEAR")
    const res = localStorage.clear();
    return res;
}

export function setCookie(key, value) {
    Cookie.set(
        key,
        JSON.stringify(value),
        { expires: 365 * 100 } // 100 year cookie expire time
    )
}

export async function getCookie(key) {
    return await Cookie.get(key);
}

export function removeCookie(key) {
    return Cookie.remove(key)
}

export function clearAllStorage() {
    sessionStorage.clear()
    const a = document.cookie.split(';');
    const b = a.map(cookie => cookie.trim().split('=')[0]);
    (b || []).forEach((key) => {
        removeCookie(key)
    });
}

export function setRecentSearch(value) {
    localStorage.setItem('recent_search', JSON.stringify(value));
}

export function getRecentSearch() {
    const p = localStorage.getItem('recent_search')
    if (p) {
        return JSON.parse(p);
    }
    return p || []
}

export function addRecentSearch(value) {
    let recent_search = getRecentSearch() || [];
    recent_search = recent_search.filter((x) => x !== value);
    recent_search.unshift(value);
    setRecentSearch(recent_search.slice(0, 5));
}

export function getLanguageFromStorage() {
    return localStorage.getItem('language');
}

export function setLanguageToStorage(language) {
    return localStorage.setItem('language', language);
}

export function getSplashScreenDisplayDate() {
    return localStorage.getItem('splash_screen_display_date');
}

export function setSplashScreenDisplayDate(date) {
    return localStorage.setItem('splash_screen_display_date', date);
}