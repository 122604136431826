// FormSection.js
const FormSection = ({ title, children, sectionClass = "" }) => {
  return (
    <div className={sectionClass}>
      <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        {title}
      </h6>
      <div className="flex flex-wrap">{children}</div>
      <hr className="mt-6 border-b-1 border-blueGray-300" />
    </div>
  );
};

export default FormSection;
