import React, { useCallback, useEffect, useState } from "react";
import FormSection from "components/Form/FormSection.js";
import Input from "components/Form/Input.js";
import Textarea from "components/Form/Textarea.js";
import Button from "components/Form/Button.js";
import Checkbox from "components/Form/Checkbox.js";
import { UserService } from "libs/api/services/UserService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router";
import Select from "components/Form/Select";

export default function UserForm({ existingData }) {
    const history = useHistory();
    const { id } = useParams();
    const [roles, setRoles] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        roles: [],
    });

    useEffect(() => {
        const getUserDetails = async (id) => {
            if (roles && id) {
                const response = await UserService.getUserInfo(id);
                if (response.ok) {
                    setFormData(response.data)
                }
            }
        }

        getUserDetails(id);
    }, [roles, id])

    useEffect(() => {
        const getRoles = async () => {
            const response = await UserService.getRoles();
            if (response.ok) {
                setRoles(response.data)
            }
        }

        getRoles();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === "roles" ? [value] : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form Data: ", formData); // Handle form submission
        const response = id ? await UserService.update(id, formData) : await UserService.register(formData);
        if (response.ok) {
            history.push('/admin/users');
        }
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">User</h6>
                                <Button
                                    text="Save"
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <form onSubmit={handleSubmit}>
                                {/* User Information */}
                                <FormSection title="User Information">
                                    <Input
                                        label="Name"
                                        name="name"
                                        defaultValue={formData.name}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    />
                                    <Input
                                        label="Email"
                                        name="email"
                                        type="email"
                                        defaultValue={formData.email}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    />
                                    <Input
                                        label="Phone"
                                        name="phone"
                                        defaultValue={formData.phone}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    />
                                    <Input
                                        label="Password"
                                        name="password"
                                        defaultValue={formData.password}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    />
                                    <Select
                                        label="Role"
                                        name="roles"
                                        options={roles.map(role => ({ label: role.name, value: role.name }))}
                                        onChange={handleChange}
                                        wrapperClass="w-full lg:w-6/12 px-4"
                                        inputClass="w-full"
                                    />
                                </FormSection>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
