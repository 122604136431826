import React, { useCallback } from "react";
import PropTypes from "prop-types";

// components
import moment from "moment";
import Checkbox from "components/Form/Checkbox";
import ReactPaginate from "react-paginate";

export default function CardTable({ title, color = "light", columns, data, actions, hasNo, page = 1, setPage, last_page, limit = 10, hasCreateBtn, onClickCreate }) {
  // Invoke when user click to request another page.
  const handlePageClick = useCallback((event) => {
    if (setPage) {
      setPage(event.selected + 1);
    }
  }, [setPage]);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center justify-between">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                {title}
              </h3>
            </div>
            {hasCreateBtn && <button className="mr-3" onClick={onClickCreate}>
              Create New
            </button>}
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                {hasNo && <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >No.</th>}
                {columns.filter(x => x.show === undefined || x.show).map((column, idx) => (
                  <th
                    key={idx}
                    className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                    style={column.width ? { width: column.width, minWidth: column.width, maxWidth: column.width, overflowWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
                  // width={column.width}
                  >
                    {column.name}
                  </th>
                ))}
                {/* Action column */}
                {actions && actions.length > 0 && <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >

                </th>}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {hasNo && <td
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-center whitespace-nowrap p-4"
                  >{rowIndex + 1 + ((page - 1) * limit)}.</td>}
                  {columns.filter(x => x.show === undefined || x.show).map((column, colIndex) => {
                    const [firstCol, secondCol] = column.col.split(".");
                    console.log({firstCol, secondCol})
                    let value = secondCol ? row[firstCol][secondCol] : row[firstCol];
                    switch (column.type) {
                      case "date": value = value ? moment(value).format(column.format || 'YYYY-MM-DD') : ''; break;
                      default: break;
                    }
                    return (
                      <td
                        key={colIndex}
                        className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs ${column.position ? `text-${column.position}` : ''} whitespace-nowrap p-4`}
                        style={column.width ? { width: column.width, minWidth: column.width, maxWidth: column.width, overflowWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
                      // width={column.width}
                      >
                        {/* Special case for Project column rendering an image and text */}
                        {column.image ? (
                          <div className="flex items-center">
                            <img
                              src={require(`assets/img/${row.image}`).default}
                              className="h-12 w-12 bg-white rounded-full border"
                              alt="..."
                            />
                            <span
                              className={
                                "ml-3 font-bold " +
                                (color === "light"
                                  ? "text-blueGray-600"
                                  : "text-white")
                              }
                            >
                              {column.link ? <a href={value}>{value}</a> : value}
                            </span>
                          </div>
                        ) :
                          column.type === "check_box" ? (
                            <>
                              <Checkbox
                                checked={value}
                                onChange={(e) => column.onAction && column.onAction(e, row)}
                                wrapperClass="w-full lg:w-6/12 px-4 cursor-pointer"
                              />
                            </>
                          ) : (
                            column.link ? <a href={(value.includes("http") ? "" : "https://") + value} target="__blank" style={{ color: 'purple' }}>{value}</a> : value?.toString() // Default rendering for other columns
                          )}
                      </td>
                    )
                  })}
                  {/* Actions dropdown */}
                  {actions && actions.length > 0 && <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                    <div className="flex flex-row gap-x-1 content-around justify-end">
                      {actions.filter(x => x.show === undefined || x.show).map((action, idx) => (
                        <div
                          key={idx}
                          className={`text-sm p-2 content-center text-center font-normal block whitespace-nowrap bg-transparent text-white ${action.type === "delete" ? "bg-red-500" : action.type === "edit" ? "bg-orange-500" : "bg-lightBlue-600"} border rounded cursor-pointer`}
                          onClick={(e) => {
                            e.preventDefault();
                            action.fnc(row);
                          }}
                          style={{ minWidth: 40 }}
                        >
                          {
                            action.type === "details" ?
                              <i className="fas fa-eye"></i>
                              : action.type === "edit" ?
                                <i className="fas fa-edit"></i>
                                : action.type === "delete" ?
                                  <i className="fas fa-trash"></i>
                                  : action.name
                          }
                        </div>
                      ))}
                    </div>
                  </td>}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={columns.length + (hasNo ? 1 : 0) + (actions ? 1 : 0)}>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<i className="fas fa-chevron-right"></i>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={last_page || 0}
                    previousLabel={<i className="fas fa-chevron-left"></i>}
                    renderOnZeroPageCount={null}
                    containerClassName="flex pl-0 rounded list-none flex-wrap py-2 float-right mr-4 pr-4 my-4"
                    pageClassName="first:ml-0 text-xs font-semibold flex w-8 h-8 ml-1 mr-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500 cursor-pointer"
                    pageLinkClassName="w-full h-full flex items-center justify-center"
                    previousClassName="first:ml-0 text-xs font-semibold flex w-8 h-8 mr-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500"
                    nextClassName="first:ml-0 text-xs font-semibold flex w-8 h-8 ml-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500"
                    activeClassName="bg-lightBlue-500"
                    activeLinkClassName="text-white"
                    breakClassName="text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-lightBlue-500 bg-white text-lightBlue-500"
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired, // Column display name
      col: PropTypes.string.isRequired,  // Corresponding key in data object
      image: PropTypes.string,
      position: PropTypes.string,
      link: PropTypes.bool,
      type: PropTypes.oneOf(["date", "check_box"]),
      format: PropTypes.string,
      onAction: PropTypes.func,
      width: PropTypes.string,
      show: PropTypes.bool,
    })
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired, // Action name displayed in dropdown
      fnc: PropTypes.func.isRequired,    // Function to execute on action click
      type: PropTypes.oneOf(["details", "edit", "delete"]),
      show: PropTypes.bool,
    })
  ).isRequired,
  page: PropTypes.number,
  setPage: PropTypes.func,
  last_page: PropTypes.number,
  limit: PropTypes.number,
  hasCreateBtn: PropTypes.bool,
  onClickCreate: PropTypes.func
};
