const Textarea = ({
  label,
  defaultValue,
  rows = 4,
  textareaClass = "",
  wrapperClass = "",
  ...rest
}) => {
  return (
    <div className={`relative w-full mb-3 ${wrapperClass}`}>
      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        {label}
      </label>
      <textarea
        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 ${textareaClass}`}
        defaultValue={defaultValue}
        rows={rows}
        {...rest}
      ></textarea>
    </div>
  );
};

export default Textarea;