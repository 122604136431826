const APIEndpoint = process.env.REACT_APP_API_URL;

export const Endpoint = {
  Base: APIEndpoint,

  // AuthSingleton
  Register: `${APIEndpoint}/api/auth/register`,
  Login: `${APIEndpoint}/api/auth/login`,

  // UserService
  UserProfile: `${APIEndpoint}/api/users/:id`,
  UserList: `${APIEndpoint}/api/users`,
  UserRegister: `${APIEndpoint}/api/auth/register`,
  RoleList: `${APIEndpoint}/api/roles`,
  
  // InquiryService
  InquiryList: `${APIEndpoint}/api/inquiries`,
  InquiryRD: `${APIEndpoint}/api/inquiries/:id`,

  // GuestService
  GuestList: `${APIEndpoint}/api/guests`,
  GuestRD: `${APIEndpoint}/api/guests/:id`,

  // CommentService
  CommentList: `${APIEndpoint}/api/comments`,
  CommentRD: `${APIEndpoint}/api/comments/:id`,

  // InvitationService
  InvitationList: `${APIEndpoint}/api/invitations`,
  InvitationRUD: `${APIEndpoint}/api/invitations/:id`,
  InvitationCreate: `${APIEndpoint}/api/invitations/create`,
};
