import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Inquiries from "views/admin/Inquiries.js";
import Users from "views/admin/user/index.js";
import UserForm from "views/admin/user/form.js";
import Invitations from "views/admin/invitation/index.js";
import InvitationForm from "views/admin/invitation/form.js";
import Guests from "views/admin/Guests.js";
import Comments from "views/admin/Comments.js";

export default function Admin() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/inquiries" exact component={Inquiries} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/users/create" exact component={UserForm} />
            <Route path="/admin/invitations" exact component={Invitations} />
            <Route path="/admin/invitations/create" exact component={InvitationForm} />
            <Route 
              path="/admin/invitations/:id" 
              exact 
              render={(props) => <InvitationForm {...props} type="edit" />} 
            />
            <Route path="/admin/invitations/:id/guests" exact component={Guests} />
            <Route path="/admin/invitations/:id/comments" exact component={Comments} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
