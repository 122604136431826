const Select = ({
  label,
  type = "text",
  defaultValue,
  placeholder,
  inputClass = "",
  wrapperClass = "",
  options,
  ...rest
}) => {
  return (
    <div className={`relative w-full mb-3 ${wrapperClass}`}>
      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
        {label}
      </label>
      <select
        type={type}
        className={`border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150 ${inputClass}`}
        placeholder={placeholder}
        {...rest}
      >
        <option value={""}>Please select User</option>
        {
          options.map((option, index) =>
            <option key={index} value={option.value}>{option.label}</option>
          )
        }
      </select>
    </div>
  );
};

export default Select;