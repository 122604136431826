import Swal from "sweetalert2";
import { Endpoint } from "../endpoints";
import { ApiService } from "../networking";

export class CommentService {
  static getList = (id, page = 1, limit = 10) => {
    return new Promise((resolve) => {
      ApiService.GET(`${Endpoint.CommentList}?id=${id}&page=${page}&limit=${limit}`)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET COMMENT LIST RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err });
        });
    });
  };

  static handleCRUD = (id, method, body) => {
    return new Promise((resolve) => {
      ApiService.FETCH(Endpoint.CommentRD.replace(":id", id), method, [[]], body)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            method.toUpperCase() + " COMMENT RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error',
          })
          return resolve({ ok: false, data: null, error: err });
        });
    });
  }

  static getDetails = (id) => {
    return this.handleCRUD(id, 'GET');
  };

  static delete = (id) => {
    return this.handleCRUD(id, 'DELETE');
  };
}