import Swal from "sweetalert2";
import { Endpoint } from "../endpoints";
import { ApiService } from "../networking";

export class UserService {
  static getUserInfo = (id) => {
    return new Promise((resolve) => {
      ApiService.GET(Endpoint.UserProfile.replace(":id", id))
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET USER'S PROFILE RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err });
        });
    });
  };

  static getUsers = () => {
    return new Promise((resolve) => {
      ApiService.GET(Endpoint.UserList)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET USER LIST RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err.response.message });
        });
    });
  };

  static getRoles = () => {
    return new Promise((resolve) => {
      ApiService.GET(Endpoint.RoleList)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET ROLE LIST RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err.response.message });
        });
    });
  };

  static register = (data) => {
    return new Promise((resolve) => {
      ApiService.POST(Endpoint.UserRegister, [[]], data)
        .then((data) => {
          return resolve({ ok: true, data: data.data, error: null });
        })
        .catch((err) => {
          console.log(
            "GET USER REGISTER RESPONSE API CALL ERROR: ",
            err
          );
          Swal.fire({
            title: err.status === 401 ? "Unauthorized" : err.status === 403 ? "Forbidden" : "Something went wrong.",
            text: err?.response?.message || "",
            icon: 'error'
          })
          return resolve({ ok: false, data: null, error: err.response.message });
        });
    });
  };
}