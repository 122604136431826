// Checkbox.js
const Checkbox = ({
  id,
  label,
  linkText,
  linkHref = "#",
  onLinkClick = (e) => e.preventDefault(),
  checkboxClass = "",
  labelClass = "",
  linkClass = "",
  checked = false,
  onChange,
  ...rest
}) => {
  return (
    <label className={`inline-flex items-center cursor-pointer ${labelClass}`}>
      <input
        id={id}
        type="checkbox"
        className={`form-checkbox border-blueGray-700 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150 ${checkboxClass}`}
        value={checked}
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <span className="ml-2 text-sm font-semibold text-blueGray-600">
        {label}{" "}
        {linkText && (
          <a href={linkHref} className={`text-lightBlue-500 ${linkClass}`} onClick={onLinkClick}>
            {linkText}
          </a>
        )}
      </span>
    </label>
  );
};

export default Checkbox;
