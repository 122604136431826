import React, { useCallback, useEffect, useState, useMemo } from "react";

// components
import CardTable from "components/Cards/CardTable.js";
import { CommentService } from "libs/api/services/CommentService";
import { useParams } from "react-router";

export default function Comments() {
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState();
  const { id } = useParams();

  const getComments = useCallback(async () => {
    const response = await CommentService.getList(id, page);
    if (response.ok) {
      setComments(response.data.data);
      setLast_page(response.data.meta.last_page);
    }
  }, [id, page]);

  const handleDelete = useCallback(async (id) => {
    const response = await CommentService.delete(id);
    if (response.ok) {
      getComments();
    }
  }, [getComments]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const actions = useMemo(() => [
    { name: "Delete", fnc: (row) => handleDelete(row.id), type: "delete" },
  ], [handleDelete]);

  const columns = useMemo(() => [
    { name: "Date", col: "createdAt", type: "date", format: "YYYY-MM-DD HH:mm" },
    { name: "Name", col: "name" },
    { name: "Blessing Message", col: "message", width: 400 },
  ], []);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <CardTable
          title="Comments"
          hasNo
          color="light"
          page={page}
          setPage={setPage}
          last_page={last_page}
          columns={columns}
          data={comments}
          actions={actions}
        />
      </div>
    </div>
  );
}
