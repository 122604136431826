import React, { useCallback, useEffect, useState, useMemo } from "react";

// components
import CardTable from "components/Cards/CardTable.js";
import { InquiryService } from "libs/api/services/InquiryService";

export default function Inquiries() {
  const [inquiries, setInquiries] = useState([]);
  const [page, setPage] = useState(1);
  const [last_page, setLast_page] = useState();

  const getInquiries = useCallback(async () => {
    const response = await InquiryService.getList(page);
    if (response.ok) {
      setInquiries(response.data.data);
      setLast_page(response.data.meta.last_page);
    }
  }, [page]);

  const handleChecked = useCallback(async (_, row) => {
    const response = await InquiryService.update({ ...row, checked: !row.checked });
    if (response.ok) {
      setInquiries((prevInquiries) => 
        prevInquiries.map((x) => 
          x.id === row.id ? { ...x, checked: response.data.checked } : x
        )
      );
    }
  }, []);

  const handleDelete = useCallback(async (id) => {
    const response = await InquiryService.delete(id);
    if (response.ok) {
      getInquiries();
    }
  }, [getInquiries]);

  useEffect(() => {
    getInquiries();
  }, [getInquiries]);

  const actions = useMemo(() => [
    { name: "Delete", fnc: (row) => handleDelete(row.id), type: "delete" },
  ], [handleDelete]);

  const columns = useMemo(() => [
    { name: "Checked", col: "checked", type: "check_box", position: "center", onAction: handleChecked },
    { name: "Date", col: "createdAt", type: "date", format: "YYYY-MM-DD HH:mm" },
    { name: "Name", col: "name" },
    { name: "Phone", col: "phone" },
    { name: "Message", col: "message" },
  ], [handleChecked]);

  return (
    <div className="flex flex-wrap mt-4">
      <div className="w-full mb-12 px-4">
        <CardTable
          title="Inquiries"
          hasNo
          color="light"
          page={page}
          setPage={setPage}
          last_page={last_page}
          columns={columns}
          data={inquiries}
          actions={actions}
        />
      </div>
    </div>
  );
}
