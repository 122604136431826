import { ApiService } from '../networking';
import { Endpoint } from '../endpoints';

class AuthSingleton {
    static _instance;

    // Shared instance accessor
    static get instance() {
        if (!this._instance) {
            this._instance = new AuthSingleton();
        }

        return this._instance;
    }

    async login(email, password) {
        return new Promise((resolve) => {
            ApiService.POST(Endpoint.Login, [[]], { email, password })
                .then((data) => {
                    return resolve({ ok: true, data: data, error: null });
                })
                .catch((err) => {
                    console.log("LOGIN RESPONSE API CALL ERROR: ", err);
                    return resolve({ ok: false, data: null, error: err });
                });
        });
    };
}

export default AuthSingleton;
