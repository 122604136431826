/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { getToken } from '../../utils/Storage';
import { useHistory } from 'react-router';

const WithAuthCheck = (
    WrappedComponent
) => {
    const AuthCheck = (props) => {
        const history = useHistory();
        // Perform your authentication check here
        const isAuthenticated = !!getToken(); ///* Add your authentication logic here */ false;

        useEffect(() => {
            // Redirect to login if not authenticated
            if (!isAuthenticated) {
                // Redirect to the login page
                // You can customize the login route as needed
                history.push('/auth/login')
            }
        }, [isAuthenticated]);

        return isAuthenticated ? <WrappedComponent {...props} /> : null;
    };

    return AuthCheck;
};

export default WithAuthCheck;
